import { BsBriefcaseFill } from "react-icons/bs";
import { GiDiscussion } from "react-icons/gi";
import { FaUserEdit } from "react-icons/fa";
import { GiFiles } from "react-icons/gi";
import { BiLogOut } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import { MdReviews } from "react-icons/md";
import { BiSolidJoystick } from "react-icons/bi";
import Constant from "@/staticData/constant";

export const genericHeaderItems = {
  navList: {
    jobs: {
      id: "jobs",
      label: "Jobs",
      link: `/jobs`,
    },
    // scholarships: {
    //   id: "scholarships",
    //   label: "Scholarships",
    //   link: "/courses#Scholarships",
    //   // meta: { text: "Upto 100%" },
    // },
    courses: {
      id: "courses",
      label: "Courses",
      link: `/courses`,
    },
    resources: {
      id: "resources",
      label: "Resources",
      link: `/resources`,
    },
    companies: {
      id: "companies",
      label: "Companies",
      link: "/companies",
    },
    successStories: {
      id: "successStories",
      label: "Placements",
      link: `/success-stories`,
    },
    community: {
      id: "community",
      label: "Community",
      subMenu: {
        practice: {
          id: "practice",
          label: "Free Assessments",
          link: `/practice`,
        },
        questionBank: {
          id: "questions",
          label: "MCQ Questions",
          link: `/mcqs`,
        },
        // "daily-question": {
        //   id: "daily-question",
        //   label: "Interview Practice",
        //   link: `/daily-question`,
        // },
        shorts: {
          id: "shorts",
          label: "Short Videos",
          link: `/shorts`,
        },
        wallpapers: {
          id: "wallpapers",
          label: "Wallpapers",
          link: `/wallpapers`,
        },
        // blogs: {
        //   id: "blogs",
        //   label: "Blogs",
        //   link: `/blogs`,
        //   target: "blank",
        // },
        join_discord: {
          id: "discord",
          label: "Join our Discord",
          link: `${Constant.OUTSCAL_DISCORD_SERVER}`,
          target: "blank",
        },
        web_stories: {
          id: "web_stories",
          label: "Web Stories",
          link: `/webstories`,
          target: "blank",
        },
        compilers: {
          id: "compilers",
          label: "Online Compilers",
          link: `/online-compilers/cpp`,
          target: "blank",
        },
      },
    },
  },
  profileMenu: {
    jobIntroductions: {
      id: "jobIntroductions",
      icon: BsBriefcaseFill,
      label: "My Jobs",
      link: "/job-applications",
    },
    bookings: {
      id: "bookings",
      icon: GiDiscussion,
      label: "My Bookings",
      link: "/bookings",
    },
    editProfile: {
      id: "editProfile",
      icon: FaUserEdit,
      label: "Edit Profile",
      link: "/profile/edit",
    },
    //link getting set in useEffect
    portfolio: {
      id: "portfolio",
      icon: GiFiles,
      label: "My Profile",
      link: "/",
    },
    games: {
      id: "games",
      icon: BiSolidJoystick,
      label: "My Games",
      link: "/",
    },
    refer: {
      id: "refer",
      label: "Refer & Earn",
      icon: GiReceiveMoney,
      link: "/profile/edit?editProfileTab=refer",
    },
    reviews: {
      id: "reviews",
      icon: MdReviews,
      label: "Reviews",
    },
    logout: {
      id: "logout",
      icon: BiLogOut,
      label: "Logout",
      action: (logout) => {
        logout();
      },
    },
  },
};

export const profileOnlyHeaderItems = {
  profileMenu: {
    jobIntroductions: {
      id: "jobIntroductions",
      icon: BsBriefcaseFill,
      label: "My Jobs",
      link: "/job-applications",
    },
    bookings: {
      id: "bookings",
      icon: GiDiscussion,
      label: "My Bookings",
      link: "/bookings",
    },
    editProfile: {
      id: "editProfile",
      icon: FaUserEdit,
      label: "Edit Profile",
      link: "/profile/edit",
    },
    //link getting set in useEffect
    portfolio: {
      id: "portfolio",
      icon: GiFiles,
      label: "My Profile",
      link: "/",
    },
    games: {
      id: "games",
      icon: BiSolidJoystick,
      label: "My Games",
      link: "/",
    },
    refer: {
      id: "refer",
      label: "Refer & Earn",
      icon: GiReceiveMoney,
      link: "/profile/edit?editProfileTab=refer",
    },
    logout: {
      id: "logout",
      icon: BiLogOut,
      label: "Logout",
      action: (logout) => {
        logout();
      },
    },
  },
};
