import { gql } from "@apollo/client";

const CREATE_COURSE_LEAD = gql`
  mutation CreateCourseLead(
    $name: String!
    $email: String!
    $phone_number: PhoneNumberInput
    $courseJoiningReason: String
    $utms: UtmsInput!
    $anonymousUserId: String
    $userType: String
    $leadSource: Int!
    $courseOfInterest: String
  ) {
    createCourseLead(
      name: $name
      email: $email
      phone_number: $phone_number
      courseJoiningReason: $courseJoiningReason
      utms: $utms
      anonymousUserId: $anonymousUserId
      userType: $userType
      leadSource: $leadSource
      courseOfInterest: $courseOfInterest
    ) {
      user {
        email
        _id
      }
    }
  }
`;

const UPDATE_COURSE_LEAD_AUTH = gql`
  mutation UpdateCourseLeadAuth(
    $courseLeadEmail: String!
    $authEmail: String!
  ) {
    updateCourseLeadAuth(
      courseLeadEmail: $courseLeadEmail
      authEmail: $authEmail
    ) {
      user {
        email
        _id
      }
    }
  }
`;

export { CREATE_COURSE_LEAD, UPDATE_COURSE_LEAD_AUTH };
